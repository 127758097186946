import { Controller } from '@hotwired/stimulus'
import MarkerClusterer from '@google/markerclusterer'
import { Loader } from '@googlemaps/js-api-loader'
import CookieLanguageHelper from '../helpers/cookie_language_helper'

export default class extends Controller {
  static values = { data: Object }

  connect () {
    const loader = new Loader({
      apiKey: '',
      version: 'weekly',
      libraries: ['places']
    })

    loader
      .importLibrary('maps')
      .then(({ Map }) => {
        this.initGoogleMap()
      })
      .catch((e) => {
        // do something
      })

    CookieLanguageHelper.setChartLanguage()
  }

  initGoogleMap () {
    const bounds = new google.maps.LatLngBounds()

    const map = new google.maps.Map(this.element)
    const infowindow = new google.maps.InfoWindow()
    const icon_url = this.data.get('icon')
    const image = {
      url: icon_url,
      scaledSize: new google.maps.Size(20, 20),
    }
    const markers = this.dataValue.places.map(p => {
      const marker = new google.maps.Marker(
        {
          position: { lat: +p.latitude, lng: +p.longitude },
          map: map,
          icon: image,
          title: p.name,
          address: p.address
        }
      )
      const loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng())
      bounds.extend(loc)
      marker.addListener('click', () => {
        infowindow.setContent(`<h5>${marker.title}</h5><p>${marker.address || ''}</p>`)
        infowindow.open(map, marker)
      })
      return marker
    })

    map.fitBounds(bounds)
    map.panToBounds(bounds)
    map.addListener('click', () => infowindow.close())

    new MarkerClusterer(map, markers, {
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      gridSize: 15
    })
  }
}
