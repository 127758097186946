import '../stylesheets/application';
import CookieLanguageHelper from "../src/javascript/helpers/cookie_language_helper";

import 'jquery/src/jquery';

/** Bootstrap */
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
window.bootstrap = bootstrap;

/** NioApp */
import NioApp from 'nioapp/nioapp.min.js';
window.NioApp = NioApp;

import 'simplebar/dist/simplebar';
import 'select2/dist/js/select2.full';
import 'sweetalert2/dist/sweetalert2';
import 'toastr/build/toastr';
import 'jquery-validation/dist/jquery.validate';
import 'slick-carousel/slick/slick';

/** ClipboardJS */
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

/** ChartJS */
import Chart from 'chart.js/auto';
window.Chart = Chart;

/** Import chartjs-plugin-datalabels */
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

// Disable datalabels globally by default
Chart.defaults.set('plugins.datalabels', {
    display: false
});

/** noUiSlider */
import 'nouislider/distribute/nouislider';

/** DataTable */
import 'datatables.net/js/jquery.dataTables.min';
import 'datatables.net-responsive/js/dataTables.responsive.min';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4.min';

/** Dropzone */
import 'dropzone/dist/min/dropzone.min';

/** jQuery Knob */
import 'knob/jquery.knob.min';

/** jQuery Steps */
import 'jquery-steps/jquery.steps.min';

/** jQuery Magnific Popup */
import 'magnific-popup/dist/jquery.magnific-popup';

/** Code Prettify */
import 'prettify';

/** Turbo */
import { Turbo } from "@hotwired/turbo-rails"
Turbo.start()

/** Stimulus controllers */
import 'src/javascript/controllers/index';

/** Images */
import '../images/loader.svg';

/** Numeral */
import numeral from 'numeral';
import 'numeral/locales/pl';
numeral.locale('pl');

// Configure i18n-js
import { I18n } from "i18n-js";
import * as translations from '../locales/translations.json';

async function loadTranslations() {
    window.i18n = new I18n();
    const userLocale = CookieLanguageHelper.getLocale();

    try {
        i18n.store(translations);
        i18n.defaultLocale = "pl";
        i18n.enableFallback = true;
        i18n.locale = userLocale;
    } catch (error) {
        console.error('Error:', error);
    }
}

await loadTranslations();

/** Google map */
import GoogleMapsCallback from "src/javascript/maps/google_maps_callback_helper";

let promiseResolver = function() {};
const promise = new Promise((res) => {
    promiseResolver = res;
});

window.googleMapCallback = () => {
    promiseResolver();
};

GoogleMapsCallback.init(promise);
window.googleMapsKey = '<%= BlixClientPanel::Application.credentials[:google][:maps] %>';

require('dashlite/js/scripts');
